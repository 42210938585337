#headerContainer{
    height: 80px;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
#headerText{
    text-decoration: none;
    color: #101828;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 18px; /* 90% */
}
@media only screen and (max-width: 900px) {
    #headerText{
        color: #101828;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 90% */
    }
    #headerContainer{
        height: 72px;
    }
}
