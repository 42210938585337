#homeTitle {
  color: var(--gray-900, #101828);
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.96px;
}
#homeContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#allHome {
  width: 75%;
  display: flex;
  flex-direction: column;
}
#topInfo {
  margin-top: 90px;
  margin-bottom: 60px;
  display: flex;
  max-width: 1280px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  text-align: start;
  /* align-self: stretch; */
}
.homeDescText {
  width: 70%;
}
.cardInfo {
  margin-top: 20px;
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.cardTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.titleATag {
  text-decoration: none;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
#cardContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 60px;
}
.cardSubtext {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #475467;
}
.homeCard {
  margin: 40px 0px;
  width: 31%;
  height: 100%;
}
.cardImg {
  width: 100%;
  height: 100%;
  transition: all ease 0.5s;
  border-radius: 18px;
}
.jobTitle {
  color: #3f88c5;

  /* Text md/Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.descText {
  color: var(--gray-600, #475467);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
.cardTitleText {
  color: var(--gray-900, #101828);
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}
.comingSoon{
  transition: all ease 0.5s;
}
.hoverCursor:hover{
  cursor: pointer;
}

.comingSoon:hover{
  filter: grayscale(100);
}
.comingSoon:hover {
  opacity: 1;
}

.cardImg:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  scale: 1.009;
}

@media only screen and (max-width: 1200px) {
  #allHome {
    width: 90%;
  }
  .homeCard {
    width: 48%;
  }
  .cardTitleText {
    font-size: 24px;
  }
  .cardSubtext {
    font-size: 18px;
  }
}
@media only screen and (max-width: 750px) {
  #allHome {
    flex-direction: column;
  }
  #cardContainer {
    flex-direction: column;
    align-items: center;
  }
  .homeCard {
    width: 100%;
  }
  #topInfo{
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: 600px) {
  .cardTitleText {
    font-size: 20px;
  }
  .cardSubtext {
    font-size: 16px;
  }
  #homeTitle {
    color: var(--gray-900, #101828);

    /* Display md/Semibold */
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px; /* 122.222% */
    letter-spacing: -0.72px;
  }
  .homeDescText {
    width: 100%;
    color: var(--gray-600, #475467);

    /* Text lg/Regular */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
  }
}
