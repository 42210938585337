@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter';
}

.App {
    text-align: center;

    width: 100%;
    height: 100%;
  }


  html {
    scroll-behavior: smooth;
  }

  @media screen and (max-width: 640px)
{
    body, html{
        width: 100vw;
        overflow-x: hidden;
    }
}