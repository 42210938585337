#footerContainer{
    width: 90%;
    display: flex;
    height: 100%;
    flex-direction: row;
    margin-bottom: 25px;
    justify-content: flex-end;
    gap: 12px;
    padding-top: 25px;
    border-top: 1px solid var(--gray-200, #EAECF0);
}
#linkedInLogo{
    width: 65px;
    height: 65px;
}
.footerIcon{
    transition: all 0.2s ease-in-out;
}
.footerIcon :hover{
    cursor: pointer;
    transform: scale(1.05);


}


@media only screen and (max-width:1024px) {
    #linkedInLogo{
        width: 45px;
        height: 45px;
    }
    
}

@media only screen and (max-width:425px) {
    #linkedInLogo{
        width: 30px;
        height: 30px;
    }
    
}