#about{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(./assets/background.png);
    background-size: contain;
    width: 100%;
    background-repeat: no-repeat;
}

#aboutTitleContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width:80%;
    margin-bottom: 100px;
    margin-top: 200px;
}
#imagesContainer{
    width: 30%;
}

#aboutIntro{
    color: #333;
    font-family: Inter;
    font-size: 150px;
    font-style: normal;
    font-weight: 900;
    line-height: 99.023%;
}
#aboutTitle{
    color: #526FD8;
    font-family: Inter;
    font-size: 150px;
    font-style: normal;
    font-weight: 900;
    line-height: 99.023%;
}

#aboutContent{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /* width:70%; */
    justify-content: center;
    margin-bottom: 100px;
}

#aboutImgContainer{
    border-radius: 636px;
    background: rgba(224, 226, 246, 0.80);

    /* height: 611px; */
    width: 100%;
}

#aboutImg{
    height:100%;
    width: 100%;
}

.descTextMain {
    color: #333;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

#aboutText{
    width: 50%;
text-align: start;
margin-left: 100px;
}

@media only screen and (max-width: 1600px){
 #aboutIntro {
    font-size: 100px;
}
#aboutTitle{
    font-size: 100px;
}
}
@media only screen and (max-width:1500px) {
    .descTextMain {
        font-size: 24px;
        
      }
    #aboutIntro {
        font-size: 100px;
      }
      #aboutTitle {
        font-size: 100px;
      }
      #aboutContent{
        flex-direction: column;
        align-items: center;
      }
      #imagesContainer{
        width: 60%;
      }
      #aboutText{
        margin-top: 40px;
        width: 80%;
        margin-left: 0px;
      }
}

@media only screen and (max-width:1200px) {
    #aboutIntro {
        font-size: 80px;
      }
      #aboutTitle {
        font-size: 80px;
      }
      #aboutContent{
        margin-bottom: 40px;
      }
}
@media only screen and (max-width:800px) {
    .descTextMain {
        font-size: 20px;
      }
      #imagesContainer{
        width: 40%;
      }
    #aboutIntro {
        font-size: 50px;
      }
      #aboutTitle {
        font-size: 50px;
      }
      #aboutTitleContainer{
        margin-top: 50px;
        margin-bottom: 50px;
      }
}
@media only screen and (max-width:600px) {
    #aboutIntro {
        font-size: 30px;
      }
      #aboutTitle {
        font-size: 30px;
      }
}

@media only screen and (max-width:425px) {
    .descTextMain {
        font-size: 16px;
      }
    
}