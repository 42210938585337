.project1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.project1__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
}

.projectInfoContainer {
  margin-top: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 32px;
}
.projectInfoImgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 40%;
}
.projectInfoImg {
  width: 100%;
  object-fit: cover;
  height: auto;
}
.projectInfoText {
  display: flex;
  flex-direction: column;
  text-align: start;
  width: 560px;
  justify-content: center;
}
.projectInfoTitle {
  color: var(--gray-900, #101828);
  font-family: Inter;
  margin-bottom: 24px;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: -1.2px;
}
.projectInfoDesc {
  color: var(--gray-600, #475467);

  /* Text xl/Regular */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
}
.projectInfoImg {
  display: flex;
  width: 761px;
  height: 640px;
}

.projectContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin-top: 96px;
}
.leftCol {
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: start;
}
.border {
  border-top: 1px solid var(--gray-200, #eaecf0);
  width: auto;
}
.projectMenu {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: start;
  justify-content: center;
  gap: 16px;
}
.projectMenuItem {
  color: var(--gray-600, #475467);
  text-decoration: none;
  /* Text md/Semibold */
  text-align: start;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  animation: fadeIn 0.5s ease-in-out;
}
.projectMenuItem:hover {
  color: #3f88c5;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.miniFooter {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 32px;
  justify-content: flex-start;
}
#miniFooterIconSvg {
  display: flex;
}
.miniFooterIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  height: 50px;
  width: 50px;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--base-white, #fff);
  /* Shadows/shadow-xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  transition: all 0.2s ease-in-out;
}
.miniFooterIcon:hover {
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.rightCol {
  width: 70%;
  display: flex;
  flex-direction: column;
}
.projectDescription {
  color: var(--gray-600, #475467);
  text-align: start;
  /* Text xl/Regular */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
}
.projectTitle {
  color: var(--gray-900, #101828);
  text-align: start;
  margin-bottom: 20px;
  margin-top: 32px;
  /* Display sm/Semibold */
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px; /* 126.667% */
}
.projectShowcaseA {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 32px;
}
.projectShowcaseCard {
  width: 48%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.projectShowcaseCardImg {
  height: 58px;
  width: 58px;
  margin-bottom: 20px;
}
.projectShowcaseCardTitle {
  color: var(--gray-900, #101828);
  text-align: center;
  margin-bottom: 8px;
  /* Text xl/Semibold */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
}
.projectShowcaseCardDesc {
  color: var(--gray-600, #475467);
  text-align: center;

  /* Text md/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.projectQuoteBreak {
  padding-left: 20px;
  color: var(--gray-900, #101828);
  border-left: 2px solid #eab749;
  text-align: start;
  margin: 48px 0px;
  font-family: Inter;
  font-size: 24px;
  font-style: italic;
  font-weight: 500;
  line-height: 36px;
}
.projectShowcaseImg {
  width: 100%;
  /* height: 480px; */
  margin-top: 48px;
}
.imageCaption {
  color: var(--gray-600, #475467);
  margin-top: 16px;
  margin-bottom: 48px;
  text-align: start;
  /* Text sm/Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.iframe {
  width: 100%;
  height: 840px;
  margin-top: 48px;
}
#projectDescAdd {
  margin-bottom: 32px;
}

.project2Quote{
  border-left: 2px solid #637675 ;
}

/* Password protection css */

#passwordContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 500px;
}
#passText {
  color: #1e1e1e;
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 20px 0px 20px 0px;
}
#passInput {
  width: 300px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #1e1e1e;
  margin: 10px 0px 20px 0px;
  padding-left: 10px;
  font-size: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
#passButton {
  width: 100px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #1e1e1e;
  margin: 10px 0px 20px 0px;
  font-size: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: #1e1e1e;
  color: white;
  cursor: pointer;
}

/* responsiveness */

@media only screen and (max-width: 1150px) {
  .projectInfoImg {
    width: 100% !important;
    height: auto !important;
  }
  .project1__container {
    width: 90% !important;
  }
}

@media only screen and (max-width: 900px) {
  .leftCol {
    display: none !important;
  }
  .projectInfoText{
    width: 100% !important;
  }
  
  .projectContent {
    width: 95% !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  .rightCol {
    width: 100% !important;
  }
  .projectInfoContainer {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
  .project1__container {
    width: 95% !important;
  }
  .projectInfoImgContainer {
    width: 100% !important;
    height: auto !important;
  }
  .projectShowcaseA {
    flex-direction: column !important;
    align-items: center !important;
  }
  .projectShowcaseCard {
    width: 80% !important;
  }
}
@media only screen and (max-width: 500px) {
  .projectInfoTitle {
    color: var(--gray-900, #101828);

    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: -0.72px;
  }

  .projectInfoDesc {
    color: var(--gray-600, #475467);

    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
  .projectDescription {
    color: var(--gray-600, #475467);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .projectTitle {
    color: var(--gray-900, #101828);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }
  .projectShowcaseCardTitle {
    color: var(--gray-900, #101828);
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
  .projectShowcaseCardDesc {
    color: var(--gray-600, #475467);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
  }
  .projectQuoteBreak{
    color: var(--gray-900, #101828);
font-family: Inter;
font-size: 20px;
font-style: italic;
font-weight: 500;
line-height: 30px;
  }
  .imageCaption{
    color: var(--gray-600, #475467);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
  }
  .projectShowcaseImg{
    height: auto;
    margin-top: 40px;
  }
  #projectDescAdd {
    margin-bottom:40px; ;
  }
  .projectContent{
    margin-top: 0px;
  }
  .iframe{
    margin-top: 40px;
  }
  .imageCaption {
    margin-bottom: 0px;
  }
  .projectShowcaseImg{
    margin-top: 40px;
  }
  .projectQuoteBreak {
    margin: 0px 0px;
  }
  .projectShowcaseA {
    margin-bottom: 40px;
  }
  .projectDescription {
    margin-top: 40px;
  }
  .projectTitle {
    margin-bottom: 0px;
  }
  .projectInfoContainer{
    margin-top: 40px;
  }
}
