@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
#contactCon {
  width: 100%;
  /* height: 100%; */
  margin-top: 50px;
  /* background-color: rgba(0, 0, 255, 0.185); */
  display: flex;
  flex-direction: column;
  align-items: center;
}

#contactTextBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  text-align: start;
}

#contactText {
  color: #1e1e1e;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

#contactTitle {
  margin-bottom: 20px;
  color: #1e1e1e;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

#messageInput1 {
  width: 1500px !important;
  height: 500px !important;
}

#topContactBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;  
  width: 80%;
  margin-top: 50px;
}

#formContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
}

#form {
  width: 100%;
}

#topContactBox {
  width: 100%;
}

#contactName,
#contactEmail {
  width: 45%;
  height: 60px;
  display: flex;
  /* margin-right: 50px; */
}
#nameInput,
#emailInput {
  width: 100%;
  color: #161c1c;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  padding: 20px;
}

#bottomContactBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}

#messageInput {
  color: #161c1c;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  padding: 20px;
  width: 100%;
  height: 337px;
}
::placeholder {
  color: #161c1c;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  /* padding: 20px; */
}

#contactFormButton {
  color: #1e1e1e;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 100% */
  letter-spacing: 0.5px;
}

#bottomButton {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 50px;
}

#bottomButton Button {
  width: 226px;
  height: 67px;
  background-color: rgba(11, 28, 186, 0.15);
  border-radius: 45px;
  border: none;
  transition: all 0.3s ease-in-out;
  margin-top: 10px;
}

#bottomButton Button:hover {
  background-color: rgba(11, 28, 186, 0.25);
  scale: 1.05;
}

@media only screen and (max-width: 1700px) {
  #messageInput1 {
    width: 1200px !important;
    height: 500px !important;
    font-family: Inter;
  }
}

@media only screen and (max-width: 1500px) {
  #messageInput1 {
    width: 1100px !important;
    height: 500px !important;
  }

  #contactTitle {
    font-size: 30px;
  }
  #contactText {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1440px) {
  #bottomButton Button {
    width: 200px;
    height: 50px;
  }
}

@media only screen and (max-width: 1300px) {
  #messageInput1 {
    width: 1000px !important;
    height: 500px !important;
  }
}

@media only screen and (max-width: 1150px) {
  #messageInput1 {
    width: 800px !important;
    height: 500px !important;
  }
  #contactTitle {
    font-size: 25px;
  }
  #contactText {
    font-size: 20px;
  }
  #nameInput {
    font-family: Inter;
    font-size: 16px;
  }
  #emailInput {
    font-family: Inter;
    font-size: 16px;
  }
  #messageInput1 {
    font-family: Inter;
    font-size: 16px;
  }
  .form-control {
    font-size: 16px;
  }
  #contactCon {
    margin-top: 0px;
  }
}
@media only screen and (max-width: 900px) {
  #messageInput1 {
    width: 600px !important;
    height: 500px !important;
  }
  #contactTitle {
    font-size: 20px;
  }
  #contactText {
    font-size: 16px;
  }

}

@media only screen and (max-width: 900px) {
  #messageInput1 {
    width: 400px !important;
    height: 300px !important;
  }
}

@media only screen and (max-width: 565px) {  

  .form-control::placeholder {
    color: #333;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 7.409px; /* 46.304% */
letter-spacing: 0.154px;
  }
  .form-control:-ms-input-placeholder {
    color: #333;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 7.409px; /* 46.304% */
    letter-spacing: 0.154px;
   }
   .form-control::-ms-input-placeholder {
    color: #333;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 7.409px; /* 46.304% */
    letter-spacing: 0.154px;
    
   }

  #bottomButton Button {
    color: #333;
text-align: center;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 400;
    width: 120px;
    height: 35px;
  }
  #topContactBox {
    flex-direction: column;
    width: 100%;
    align-items: normal;
  }
  #emailInput,
  #nameInput {
    width: 50%;
  }
  #contactName,
  #contactEmail {
    justify-content: flex-start;
    margin-bottom: 10px;
    margin-right: 0px;
    width: 200%;
  }

}

@media only screen and (max-width: 500px) {
  #messageInput1 {
    width: 200px !important;
    height: 300px !important;
  }
  #contactTitle {
    margin-bottom: 5px;}
}
